.serviceFilter {

    .filter-icon {
        position: relative;
    }

    .menu-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .carousel-arrows-container {
        display: flex;
        align-items: center;
        justify-content: space-between; /* Updated to space-between */
    }
    .angle   {
        cursor: pointer;
    }
    .angle {
        font-size: 1.5em; /* Adjust the font size as needed */
    }

    .menu-item {
        border: 2px solid transparent;
        display: flex;
        justify-content: space-evenly;
        position: relative;
    }

    .pop-up {
        position: absolute;
        top: -1rem;
    }

    .pop-up>.label {
        display: none;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    .menu-item:hover .pop-up>.label {
        display: block;
    }

    .menu-item.active.item {
        border: 2px solid #34a5d0;
        border-radius: 5px;
    }

    .ui.menu .item {
        padding: 0.8rem 1rem;
        margin: 0px;
        min-width: 20px;
    }

    .ui.menu {
        border: none;
        box-shadow: none;
        background-color: inherit;
    }
}