.fc-h-event {
    border: none !important;
}

.wrapperParent {
    margin-top: 20px;
    width: 99%;
}

.flexBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexBoxCentered {
    display: flex;
    align-items: center;
}

.fc-toolbar-title {
    font-size: 1.35rem !important;
    font-weight: 900 !important;
}