.date-picker-container {
  border: 1px solid #ccc;
  border-radius: 12px;
  margin: auto;
  width: 100%;
  text-align: center;
}

.ui.grid>.row {
  margin-bottom: 20px;
  margin-top: 20px;
}

.apply-button-container {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.apply-button {
  width: 104px;
  padding: 14px 0px;
}

.center-align {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}