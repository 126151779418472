.clone-page {
    padding: 0 20px;
    .filters {
        display: flex;
        align-items: center;
        > div {
            padding: 0 0.5rem;
        }
        .facility-dropdown {
            min-width: 112px;
        }
    }
    .caption {
        color: rgb(160, 160, 160);
        font-weight: 600;
    }
    .button-container {
        button {
            margin-top: 1.14rem;
        }
    }
    .relative{
        position: relative;
    }
}
.ml-4 {
    margin-left: 4px !important;
}
