.audio-event {
    display: flex;
    align-items: center;
    white-space: normal;
    gap: 2px;
    white-space: normal;
    word-wrap: break-word !important;
    display: 'flex' !important;
    overflow-wrap: 'break-word' !important;
    overflow: auto !important;
}