.hover-grey:hover {
    background-color: rgb(199, 192, 192);
  }
  .file-div {
    display: inline-block;
    padding: 5px; 
    transition: background-color 0.3s ease; 
    border-radius: 5px;
  }
 .center-style {
   display: flex;
   justify-content: center;
   align-items: center;

   .edit-button:hover {
     background-color: #2987cd;
     color: white;
   }

   .delete-button:hover {
     background-color: red;
     color: white;
   }

 }