.Notifications {
    padding: 10px 20px;

    .NotificationItem.Comment {
        margin-bottom: 30px;
    }
}

.menu-tab {
    margin-top: 30px;
    width: 100%;

    .menu {
        margin-bottom: 5px !important;
    }
}

.menu-calendar-view {
    max-width: 1040px;

    .fc-event-title {
        white-space: normal;
        line-height: 1.24;
        overflow-wrap: break-word;
    }

    .fc-header-toolbar .fc-today-button {
        text-transform: capitalize;
    }
}

.mt-3 {
    margin-top: 3px !important;
}

.ui.form.form-custom-margin .fields {
    margin: 5px 0px 0px 0px;
}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
    width: 100%;
}

.fc-col-header-cell-cushion {
    color:  #2987CD !important;
}

.fc-daygrid-day-number {
    color:  #2987CD !important;
}

.announcement-input {

    .centered-placeholder::placeholder {
        text-align: center;
        font-size: 1.2rem;
        color: #5C5D5D!important;
    }
}

.align-center{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.menu-category{
    .ui.menu {
        border: none;
        box-shadow: none;
        background-color: inherit;
    }

    .ui.menu .item {
        color: #9A9A9A;
        border: none;
    }
    
    .ui.menu .active.item {
        color: #549FD7;
        background-color: inherit;
        border: none;
    }
}

.save-group-icon{
    color: #9A9A9A;
    font-weight: 300!important;
}

.light-font{
    font-weight: 100!important;
    color: #5C5D5D;
    font-family : 'Lato' !important;
}
