@import '../../../semantic-ui/site/globals/site.variables';

.category-breakfast .fc-event-title {
    color: #2987CD;
}

.category-lunch .fc-event-title {
    color: #69B546;
}

.category-dinner .fc-event-title {
    color: #EAC31F;
}

.category-snack .fc-event-title {
    color: #E98530;
}

.category-alternative .fc-event-title {
    color: #E98530;
}

.DailyMenus {
    padding: 10px 20px;
    position: relative;

    .warning-existing-menu {
        color: red;
    }

    .create-menu {
        width: 100px;
        height: 100px;
        font-size: 16px;
        border: 0;
        outline: 0;
        cursor: pointer;
        line-height: 20px;
        text-align: center;
        color: @primaryColor;
        border-radius: 5px;

        &:hover {
            background: #eee;
        }

        .button-icon {
            font-size: 30px;
            display: block;
            margin: 5px auto 0;
        }

        .text {
            margin-top: 5px;
        }
    }
    .current-audio {
        text-align: center;
        padding-top: 8px;
    }
    .ui.form {
        resize: none;
        margin-bottom: 4em;

        textarea {
            margin: 2em 0 1.5em;
        }

        .field > label {
            font-size: 1.285em;
        }
    }

    .menu-tab {
        margin-top: 15px;

        .menu {
            margin-bottom: 5px !important;
        }
    }

    h4 {
        display: inline-block;
        font-weight: normal;
        margin-right: 30px;
        font-size: 14px;
    }

    .date-filter {
        font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        margin-right: 30px;
        outline: none;
        line-height: 1.215em;
        padding: 0.4em 1em;
        font-size: 1em;
        border: 1px solid rgba(34, 36, 38, 0.15);
        transition: color 0.1s ease, border-color 0.1s ease;
    }

    .date-filter:active {
        background-color: #cce2ff;
        color: rgba(0, 0, 0, 0.87);
    }

    [type='button'] {
        padding: 0.5em 1em;
    }

    .items-list-container {
        border: none !important;
    }

    .pagination-holder {
        width: 100%;
        max-width: 650px;

        .pagination {
            position: relative;
            left: 50%;
            transform: translateX(-50%);

            @media screen and (max-width: 500px) {
                .item {
                    min-width: 1em !important;
                    padding: 0.6em 0.8em !important;
                    font-size: 14px;
                }
            }
        }
    }

    // .audio-or-text-header {
    //     width: 70%;
    //     display: flex;
    //     justify-content: flex-start;
    //     margin-bottom: 3px;
    //     span:nth-child(1) {
    //         flex-basis: 40%;
    //         margin-right: 8px;
    //     }
    //     span:nth-child(2) {
    //         flex-basis: 10%;
    //     }
    // }
    // .audio-or-text {
    //     // width: 70%;
    //     display: flex;
    //     // justify-content: space-around;
    //     align-content: flex-start;
    //     margin-bottom: 10px;

    //     .audio-recorder {
    //         margin-right: 12px;
    //         // flex-basis: 40%;

    //         .recording-audio {
    //             text-align: center;
    //             margin-right: 22px;
    //             margin-left: 30px;
    //             margin-top: 17px;
    //             padding-right: 5px;
    //         }

    //         .recording-state-image {
    //             margin-top: 9px;

    //             .audio-arrow {
    //                 display: inline-block;
    //                 height: 4.5em;
    //                 color: rgb(52, 166, 209);
    //                 padding-right: 5px;

    //                 @media screen and (max-width: 376px) {
    //                     height: 3.5em;
    //                 }

    //                 @media screen and (max-width: 330px) {
    //                     height: 3em;
    //                     margin-bottom: 2px;
    //                 }
    //             }

    //             .mic {
    //                 display: inline-block;
    //                 line-height: 1em;
    //                 color: rgb(52, 166, 209);
    //                 cursor: pointer;
    //                 height: 92px;
    //                 margin-top: 6px;
    //                 padding-top: 16px;
    //             }

    //             p {
    //                 font-size: 12px;
    //                 margin-top: auto;
    //                 padding-top: 10px;
    //                 margin-right: 2px;
    //             }

    //             .recording-button {
    //                 font-size: 0;
    //                 background-color: red;
    //                 border: 0;
    //                 border-radius: 50%;
    //                 outline: none;
    //                 cursor: pointer;

    //                 margin: auto;
    //                 margin-top: 52px;
    //                 width: 40px;
    //                 height: 40px;
    //                 animation-name: pulse-record;
    //                 animation-duration: 1.5s;
    //                 animation-iteration-count: infinite;
    //                 animation-timing-function: linear;
    //             }
    //         }

    //         .audio-exists {
    //             text-align: center;
    //             padding-top: '5px';

    //             button {
    //                 margin-top: 3px;
    //             }
    //         }
    //     }

    //     .text-description {
    //         flex-grow: 2;
    //         padding-left: 14px;
    //         border-left: 2px solid rgb(24, 52, 102);
    //         display: flex;
    //         align-items: flex-end;
    //     }
    // }

    input {
        border: 1px solid rgb(24, 52, 102) !important;
    }
    .menu-calendar-view {
        max-width: 1040px;
        .fc-event-title {
            white-space: normal;
            line-height: 1.24;
            overflow-wrap: break-word;
        }
        .fc-header-toolbar .fc-today-button {
            text-transform: capitalize;
        }
    }
    .menu-calendar-view.menu-calendar {
        .fc-event-time {
            display: none;
        }
    }
}

.edit-item-form {
    padding: 1.4rem;
    // border: 1px solid #f9ce21 !important;
    text-align: center;
    form {
        margin-top: 8px;
    }
    h3 {
        text-align: center;
    }
    .audio-or-text-header {
        text-align: center;
    }
    .audio-or-text {
        margin-bottom: 10px;

        .audio-recorder {
            margin-right: 12px;
            flex-basis: 40%;

            .recording-audio {
                text-align: center;
                margin-right: 22px;
                margin-left: 30px;
                margin-top: 17px;
                padding-right: 5px;
            }

            .recording-state-image {
                margin-top: 9px;

                .audio-arrow {
                    display: inline-block;
                    height: 4.5em;
                    color: rgb(52, 166, 209);
                    padding-right: 5px;

                    @media screen and (max-width: 376px) {
                        height: 3.5em;
                    }

                    @media screen and (max-width: 330px) {
                        height: 3em;
                        margin-bottom: 2px;
                    }
                }

                .mic {
                    display: inline-block;
                    line-height: 1em;
                    color: rgb(52, 166, 209);
                    cursor: pointer;
                    height: 92px;
                    margin-top: 6px;
                    padding-top: 16px;
                }

                p {
                    font-size: 12px;
                    margin-top: auto;
                    padding-top: 10px;
                    margin-right: 2px;
                }

                .recording-button {
                    font-size: 0;
                    background-color: red;
                    border: 0;
                    border-radius: 50%;
                    outline: none;
                    cursor: pointer;

                    margin: auto;
                    margin-top: 52px;
                    width: 40px;
                    height: 40px;
                    animation-name: pulse-record;
                    animation-duration: 1.5s;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                }
            }

            .audio-exists {
                text-align: center;
                padding-top: '5px';

                button {
                    margin-top: 3px;
                }
            }
            .current-audio {
                text-align: center;
                padding: 8px 0;
            }
        }

        .text-description {
            flex-grow: 2;
            padding-left: 14px;
            border-left: 2px solid rgb(24, 52, 102);
        }
    }
    .ui.form .field {
        margin: 0 auto;
        margin-bottom: 1em;
    }
}

@keyframes pulse-record {
    0% {
        box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);
    }
    65% {
        box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.3);
    }
    90% {
        box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0);
    }
}

.react-kanban-column {
    overflow-y: scroll !important;
    height: 90vh !important;
}

.fc-col-header-cell-cushion {
    color:  #2987CD !important;
}

.fc-daygrid-day-number {
    color:  #2987CD !important;
}

.fc-addMenu-button{
    // font-weight: bold !important;
    font-size: 1.20rem !important;
    padding: 3px 9px !important;
}

.fc-addSvcMenu-button{
    font-weight: bold !important;
    font-size: 1.65rem !important;
    padding: 0px 10px !important;
}
