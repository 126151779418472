.ProfileSettings {
    text-align: center;
    height: 100%;
    padding: 40px;
    max-width: 620px;
    margin: 0 auto;

    .formBox .input {
        /* display: block; */
        /* width: 100%; */
        margin: 10px 0;
    }
    .settings-form {
        margin: 40px 0;
        text-align: left;
    }

    .username {
        font-size: 18px;
        margin-top: 20px;
    }
}
