.list-calendar {
    border: 2px solid #2987CD !important;
    border-radius: 5px !important;
    padding: 10px !important;
}

.current-day {
    color: #ced4da !important;
    font-size: 1rem;
}

.hour-item {
    border: 1px solid #ced4da !important;
    border-bottom: 0px !important;
    border-radius: 5px !important;
    padding: 15px 10px !important;
    cursor: pointer !important;
    transition: border-color 0.1s ease-in-out, border-width 0.1s ease-in-out !important;

    &:hover {
        border: 1.5px solid #2987CD !important;
    }
}

.hour-item:last-child {
    border-bottom: 1px solid #ced4da !important;
}

.hour {
    font-weight: bold !important;
}

.event {
    word-wrap: break-word !important;
    padding: 5px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.event:hover {
    background-color: rgb(185, 184, 184);
}