.restaurant-wrapper {
    .restaurant-header {
        display: flex;
        justify-content: space-between;
    }

    .icon-placement {
        position: absolute;
        right: 0;
        bottom: -10px
    }

    .clickable {
        cursor: pointer;
        text-decoration: underline;
        color: initial;
    }

    .clickable:hover {
        color: "blue"
    }

    .table-cell {
        position: relative;
    }

    .trash-icon {
        visibility: hidden;
        position: absolute;
        cursor: pointer;
        right: 0;
        top: 12px;
    }

    .table-row:hover .trash-icon {
        visibility: visible;
    }
}
.ui.celled.table .cell-width {
    max-width: 200px;
    word-wrap: break-word;
}
