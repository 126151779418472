button.invite-community-manager.primary {
    background:rgb(52, 166, 209) !important;
}

button.invite-community-manager.negative {
    background:rgb(255, 0, 0) !important;
    border:rgb(255, 0, 0) !important;
}

.family-invite {
    order: 1;
    border: 2px solid rgb(52, 166, 209);
    border-radius: 4px;
    // width: 49%;
    padding: 10px 10px 20px 10px;

    p:nth-child(1) {
        font-size: 12px;
        color: rgb(170, 170, 170);
    }

    h3 {
        font-size: 1.2rem;
    }

    input {
        display: block;
        margin: 20px auto !important;
        width: 90% !important;
    }

    button {
        display: block !important;
        background: white !important;
        border: 1px solid rgb(52, 166, 209) !important;
        border-radius: 3px !important;
        margin: 0 auto !important;
    }

    .invite-warning {
        color: rgb(255, 0, 0);
        text-align: center;
        margin-top: 10px;
    }

    .invite-message {
        text-align: center;
        margin-top: 10px;
    }

    .relative-list {
        width: 100%;
        padding: 0.8rem 2.5rem 0;
        position: relative;
    }
    .relative-info {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 1.4vh;
        padding-top: 1.4vh;
        .relative-name {
            font-size: 1.2rem;
            color: rgb(140, 140, 140);
            margin-bottom: 0.65rem;
        }
        .relative-name-container {
            flex: 1;
            max-width: calc(100% - 80px);
            vertical-align: bottom;
        }
    }
    .relative-detail {
        .relative-info {
            cursor: unset;
            border-bottom: none;
            padding-bottom: 16px;
        }
        .ui.form {
            .field {
                margin-bottom: 0;
            }
        }
        .button-container {
            padding-top: 16px;
            text-align: center;
        }
        .relative-field {
            padding: 0.67857143em 1em;
            border: 1px solid;
            border-color: rgba(34, 36, 38, 0.15);
        }
        .ml-16 {
            margin-left: 16px;
        }
        .alexa-invite {
            padding-top: 6px;
            padding-bottom: 6px;
            input {
                border: none !important;
                padding: 0 !important;
            }
            .field {
                display: inline-block;
                .ui.field {
                    display: inline-block;
                    width: unset;
                }
            }
        }
        input {
            border-radius: 0 !important;
        }
        .relative-image-container {
            border-style: dashed;
            padding: 4px;
            border-width: 2px;
        }
    }
    .mt-80 {
        margin-top: 80px;
    }
    .light-grey {
        color: rgba(34, 36, 38, 0.15);
    }
    .flex-1 {
        flex: 1;
    }
    .buy-container {
        position: fixed;
        bottom: 0;
        text-align: center;
        width: 100%;
        padding: 8px 0 16px;
    }
    .relative-image-container {
        border: 4px solid rgb(200, 200, 200);
        border-radius: 50%;
        width: 4vw;
        height: 4vw;
        max-width: 60px;
        max-height: 60px;
        margin-right: 1rem;
        position: relative;
        cursor: pointer;

        img {
            display: inline-block;
            height: 100%;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            user-select: none;
        }

        .relative-avatar {
            background-color: #b385ea;
            font-weight: 600;
            border-radius: 50%;
            display: inline-flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 0.9rem;
        }
    }
    .invite-container{
        display: flex;
        flex-direction: column;
        align-items:center;
        padding: 5px;
    }
    .ml-8{
        margin-left: 8px;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
    }
    .delete-button {
        border: 1px red solid !important;
    }
    .w-100 {
        width: 100% !important;
    }
}
