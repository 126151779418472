
.inner-datepicker {
    font-size: 7px !important;
}
.ui.card > p > span {
    color: #979fab !important; 
}
.card-container{
    display: flex;
}
.card-container>div,
.card-container>a > div {
    margin: 10px !important;
    padding: 10px !important;
    border: 1px solid #549fd7 !important;
    text-align: center !important;
    height: 125px;
}
.card-container>div:hover{
    border: 1px solid #183466 !important;

}
.card-container > div > p,
.card-container > a >  div > p {
    font-weight: 800;
}
.card-container > a {
    width: 13.2%;
    margin: 7px;
}
.icon-div-container {
    margin-top: 21px !important;
    margin-left: 19px !important;
}

.card-container > div {
    margin-top: 18px !important;
    cursor: pointer;
    margin-left: 15px !important;
    margin-right: 0px !important;
}
.card-container > div > p {
    color : #4183C4
}

.icon-with-count .count{
    z-index:2;
}
.rightside{
    right: 0;
}