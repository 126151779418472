@import '../../../../semantic-ui/site/globals/site.variables';

.FacilitiesForm {
    padding: 0 20px;
    padding-bottom: 25px;

    .editable-image-field {
        text-align: center;
        margin-bottom: 20px;

        img {
            border-radius: 0% !important;
        }
    }
}
