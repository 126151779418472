.ui.multiple.dropdown.scrollable > .label {
    user-select: none;
    display: none;
    vertical-align: top;
    white-space: normal;
    font-size: 1em;
    padding: 0.35714286em 0.78571429em;
    box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
}
.selectedList{
    margin-top: 10px; 
}

.scroll-list{
    height: 70px;
    overflow-y : scroll;
    overflow-x: hidden;
    list-style-type: none;
    width: 100%;
    margin-bottom: 2px;
    padding-inline-start: 10px
}

.scroll-option{
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    margin: 0em 0em 0.3em;
    background-color: #E8E8E8;
    background-image: none;
    padding: 0.5833em 0.833em ;
    width: 100%;
    color: rgba(0, 0, 0, 0.6);
    text-transform: none;
    font-weight: bold;
    border: 0px solid transparent;
    border-radius: 0.28571429rem;
    transition: background 0.1s ease;
}

.deleteIcon{
     float: right;
}

.display-text{
    position: absolute;
    top: 0;
    padding: 0.5833em 0.833em ;
    z-index:10;
    color: rgba(191, 191, 191, 0.87);
}