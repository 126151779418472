.ListItem {

    .description p, .description ol {
        margin: 0 !important;
    }
    
    display: flex;
    padding: 10px 0 !important;
    
    .meta {
        display: inline-block;
    }

    .list-item-time {
        @media screen and (max-width: 700px) {
            display: block;
            width: 100%;
        }
    }

    .meta_options_column {
        display: inline-block;
        margin-right: 20px !important;
        color: blue !important;
        cursor: pointer;

        button {
            min-height: 0 !important;
            margin: 0;
            border-radius: 50% !important;
            padding: 0 !important;
            width: 45px;
            height: 45px;
            font-size: 12px !important;
            font-weight: normal;
            color: rgb(255, 255, 255);
        }

        button:hover {
            opacity: 0.7;
        }

        .editBtn {
            background: #5bc0de;
        }

        .deleteBtn {
            background: #337ab7;
        }

        .copyBtn {
            background: #5cb85c;
        }
    }

    .list-item-time {
        margin-right: 30px !important;
    }
}