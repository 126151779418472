.CreateRequest {
    .menu-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative
        }
    .menu-item {
        margin-bottom: 7px;
        border: 2px solid transparent;
        display: flex;
        justify-content: space-evenly;
        background-color: white;
    }

    .menu-item.active.item {
        background-color: white;
        border: 2px solid #34a5d0;
        border-radius: 5px;
    }

    .ui.menu .menu {
        display: flex;
        justify-content: space-between;
    }
    .ui.menu {
        border: none;
        box-shadow: none
    }
    
    .carousel-arrows-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .angle {
        cursor: pointer;
    }   
    
       
}
