.copyButton{
    background-color:#1d8e1d;
    color: white
}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
    width: 100%;
}
.required-label {
    position: relative;
    margin-left: 5px;
    font-weight: bold;
}
.mt10{
    margin-top: 10px;
}
.mb5{
    margin-bottom: 5px;
}

.required-label::after {
    content: '*';
    color: red;
    font-weight: 100;
    position: absolute;
    right: -8px;
    font-size: 16px;
    line-height: 12px;
}

.req-label-cont{
    display: flex;
    justify-content: start;
    align-items: center;
}


