@import '../../../../semantic-ui/site/globals/site.variables';

.list-all {
    border-radius: 5px;
    padding: 20px !important;
}
.training-list-container {
    table {
        border: none !important;
        outline: none !important;
        width: 100%;
    }
    td,
    th {
        padding: 7px;
        vertical-align: top;
    }
    td {
        border-bottom: 1px #f6f6f6 solid;
    }
    thead {
        color: #000 !important;
        font-size: 1rem !important;
        border-radius: 1rem !important;
        text-align: left;
    }

    thead th {
        border-bottom: 1px #2987CD solid;
        text-transform: uppercase;
    }

    thead th:nth-child(1) {
        width: 20%;
    }
    thead th:nth-child(2) {
        width: 40%;
    }
    thead th:nth-child(3) {
        width: 15%;
    }
    thead th:nth-child(4) {
        width: 10%;
    }
    thead th:nth-child(5) {
        width: 15%;
    }

    tbody tr:hover {
        background-color: #f6f6f6;
    }
}
.training-img.small {
    border-radius: 50%;
    width: 50px !important;
    min-height: 50px !important;
}
.pagination-holder-training {
    display: flex;
    justify-content: center;
}

.panel-body__top {
    display: flex;
    align-items: center;

    .search-container {
        margin-left: 100px;

        .caption {
            color: rgb(160, 160, 160);
            font-weight: 600;
        }
    }

    .radial-container {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .status-dropdown-container {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .status-dropdown {
            margin-top: 20%;
        }
        .caption {
            color: rgb(160, 160, 160);
            font-weight: 600;
        }
    }

    .total-item-count {
        .item-count {
            font-size: 2rem;
            font-weight: 600;
            text-align: center;
            line-height: 1.2;
        }
        .caption {
            color: rgb(160, 160, 160);
            font-weight: 600;
        }
    }
}
.standard-university {
    padding-top: 1.5em;
    font-size: 1.5em;
    line-height: 1.14;
    color: #ddd;
    text-align: center;
}
