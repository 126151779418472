.item.very.specific.userclass.UserItem {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    
    .profile-pic {
        max-width: 64px;
    }

    .center-self {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
    }
}
