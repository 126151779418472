.cookbook-container {
  padding: 10px;
}
.cookbook-container .cookbook-header {
  display: flex;
  justify-content: space-between;
}
.cookbook-container .dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.cookbook-container .icon-placement {
  position: absolute;
  right: 0;
  bottom: -10px;
}
.cookbook-container .clickable {
  cursor: pointer;
  text-decoration: underline;
  color: initial;
}
.cookbook-container .clickable:hover {
  color: "blue";
}
.cookbook-container .table-cell {
  position: relative;
}
.cookbook-container .trash-icon {
  visibility: hidden;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 12px;
}
.cookbook-container .table-row:hover .trash-icon {
  visibility: visible;
}
