@import '../../../semantic-ui/site/globals/site.variables';

.RequestReports {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    a {
        font-size: 18px;
    }

    .create-request {
        width: 140px;
        height: 140px;
        font-size: 18px;
        border: 0;
        outline: 0;
        cursor: pointer;
        text-align: center;
        line-height: 18px;
        text-align: center;
        color: @primaryColor;
        border-radius: 5px;

        &:hover {
            background: #eee;
        }

        .button-icon {
            font-size: 48px;
            display: block;
            margin: auto;
        }
    }

    .graphs {
        display: flex;
        justify-content: space-between;
        flex: 2;
    }

    .radial-progress {
        max-width: 320px;
    }
}
.radialDropDownBorder {
    border: none;
}
.Timeline {
    .ui.card {
        width: 100%;
    }
}

.text-top-datepicker {
    font-size: 1.2rem;
    color: darkgray;
    font-weight: bold;
    text-align: center;
}
.DashboardHome > div > .ui.segment{
    border: none;
    box-shadow : none;
    // padding: 0;
}
.react-kanban-column {
    overflow-y: scroll !important;
    height: 90vh !important;
}