@import '../../../../semantic-ui/site/globals/site.variables';

.staff-header{
    display: flex;
    gap: 10px;
}
#divider {
    border-color: @secondaryColor;
}
.pushable > .pusher {
    min-height: calc(100vh - 94px) !important;
}
.ui.segment.pushable.App {
    margin-bottom: 0;
}
.staff-dashboard {
    padding: 0 20px 0 20px;
    margin-top: -10px;

    .Requests {
        padding: 0 20px;
    }

    .no-requests,
    .ui.divided.unstackable.items {
        height: calc(50vh - 132px);
        overflow-y: scroll;
    }

    .ui.divided.unstackable.items > .item:last-child {
        margin-bottom: 80px !important;
    }

    .ui.segment.pushable > .pusher {
        height: calc(100vh - 72px) !important;
    }
}

@media (max-width: 576px) {
    .pushable > .pusher {
        min-height: calc(100vh - 142px) !important;
    }
    .staff-dashboard {
        .no-requests {
            font-size: 2em;
        }
        h2 {
            font-size: 1em;
            margin: calc(0.6rem - 0.14285714em) 0em 0.2rem;
        }

        #divider {
            margin: 0.4rem 0;
        }
    }
}
