@import '../../../../semantic-ui/site/globals/site.variables';

.UsersForm {
    padding: 0 20px;
    padding-bottom: 25px;

    .password-reset-button {
        margin: 20px 0px !important;
    }

    .reset-password-holder {
        padding: 40px 0 40px;
        border-top: 1px solid rgb(200, 200, 200);
        border-bottom: 1px solid rgb(200, 200, 200);
        margin: 40px 0;
        position: relative;

        .code-sent {
            color: red;
        }
    }

    .password-reset-warning {
        padding: 10px 0;
        color: red;
    }

    .editable-image-field {
        text-align: center;
        margin-bottom: 20px;
        min-width: 38px;
    }
}
