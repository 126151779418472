@import '../../../../semantic-ui/site/globals/site.variables';

.RequestTypesForm {
    padding: 0 20px;
    padding-bottom: 25px;

    .editable-image-field {
        text-align: center;
        margin-bottom: 20px;
    }

    .horizontal-slider {
        width: 100%;
        max-width: 500px;
        height: 26px;
    }

    .example-thumb {
        font-size: 0.4em;
        text-align: center;
        background-color: #999999;
        border-radius: 50%;
        color: white;
        cursor: pointer;
    }

    .example-thumb.active {
        background-color: rgb(35, 82, 209);
    }

    .example-track {
        position: relative;
        background: #34a6d1;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }

    .example-track.example-track-1 {
        background: #ddd;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    .example-track.example-track-2 {
        background: #0f0;
    }

    .horizontal-slider .example-track {
        top: 8px;
        height: 8px;
    }

    .horizontal-slider .example-thumb {
        top: 1px;
        width: 22px;
        height: 22px;
        line-height: 16px;
    }
    .enable-escalation{
        label{
            font-weight: 600;
        }
    }

}
