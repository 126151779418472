.selectedImages-class {
    padding: 10px 15px;
}

.deleteButtonWidget {
    width: 100%;
    display: flex;

    .ui.button {
        margin-left: auto;
    }
}