.grid.very.specific.class.RequestItem {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 10px;

    // &:hover {
    //     // box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    // }

    .profile-pic {
        max-width: 64px;
    }
}

.RequestItem-main {
    border-radius: 10px;
    background-color: #F2F4F7;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;

    &:hover {
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3)
    }
}

.RequestItem-container {
    margin-bottom: 10px;

    .ui.grid>.row {
        padding-top: 0rem;
        padding-bottom: 0rem;
    }

    .center-self {
        display: flex;
        justify-content: center;
        border-bottom: 3px black;
        position: relative;
        margin-top: 10px;
    }

    .underline {
        position: absolute;
        top: -7px;
        left: 20px;
        border-width: 1px;
        border-color: black;
        width: 90%;
    }

    .ui.header {
        font-size: medium;
    }

}

.calender-schedule :hover{
    transform: scale(1.15);
}

.blurring.dimmed.dimmable> :not(.dimmer).popup {
    // avoid blurring of popup in modal
    filter: none;
}

.order-id-cont {
    font-weight: bold;
    font-size: 12px;
    color: white;
    display: inline;
    padding: 6px;
    border-radius: 5px
}