.mx-6 {
    margin: 0px 16px !important;
}

.distance-input {
    opacity: 13.45 !important;
}

.control-arrow {
    background-color: rgb(53, 53, 53) !important;
    width: 25px;
    border-radius: 5%;
    opacity: 1 !important;
}

.carousel-status {
    z-index: 2;
    font-size: 12.5px !important;
    background-color: rgb(53, 53, 53);
    border-radius: 10% !important;
}

.wrapped {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.customDatePickerWidth,
.react-datepicker-wrapper,
.react-datepicker__input-container input,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
    width: 100% !important;
}

.custom-carousel .carousel .carousel-status {
    position: absolute;
    bottom: 0;
    right: 0;
    top: auto;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .9);
    color: #fff;
}
