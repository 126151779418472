.copyButton {
    background-color:#5cb85c !important;
    color:white !important
}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
    width: 100%;
}

.fc-col-header-cell-cushion {
    color:  #2987CD !important;
}

.fc-daygrid-day-number {
    color:  #2987CD !important;
}