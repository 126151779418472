@import '../../../../semantic-ui/site/globals/site.variables';

.CreateUser {
    padding: 0 20px;
    padding-bottom: 25px;

    .ui.form {
        margin-bottom: 20px;
    }

    .department-toggle {
        margin-bottom: 10px;
    }

    .error-message {
        color: @errorColor;
    }

    .image-field {
        text-align: center;
    }

    // .features-container {
    //     // display: flex;
    //     // flex-wrap: wrap;
    //     // justify-content: space-between;
    // }

    .tab-content {
        padding-top: 30px;
    }

    .button-holder {
        display: flex;
        flex-wrap: wrap;
        align-content: stretch;

        @media screen and (max-width: 767px) {
            justify-content: center;
        }

        button {
            @media screen and (max-width: 767px) {
                width: 100% !important;
                margin-bottom: 10px;
            }
        }
    }
}
.invite-modal-root {
    .ui.dimmer {
        background-color: rgba(0, 0, 0, 0.5);
    }
    .fnf-invite-modal-content {
        padding: 1.4em;
        .name-section {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            .field {
                flex: 1;
                // margin-right: 8px;
            }
            .firstName {
                margin-right: 8px;
                width: 100% !important;
            }
            .phone {
                margin-top: 8px;
                margin-right: 8px;
                width: 100% !important;
            }
        }
        .field,
        textarea {
            margin-bottom: 8px;
        }
    }
}
.inviteBtn {
    margin-bottom: 15px;
    margin-top: 15px;
}
.buy-container {
    position: fixed;
    bottom: 0;
    text-align: center;
    width: 100%;
    padding: 8px 0;
    background-color: #fff;
}

.staff-contacts-box {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 10px 10px;

    .create {
        margin-left: 20px;
    }
}

.staff-detail {
    .addr-name-edit {
        margin: 0px 0px 0px 10px;
    }
    .delete-staff {
        margin: 10px 0px 0px 0px;
    }
}

.inbound-progress {
    width: 100% !important;
}

.inbound-progress-text {
    margin: 10px 0px 0px 0px;
}

.address-book {
    display: flex;
    margin-top: 20px;

    .add-contacts {
        width: 100%;
        h2 {
            margin-top: 0;
        }
    }

    .s2c-manager {
        margin-top: 60px;
        width: 90%;
    }
}
.mt-4{
    margin-top: 1rem;
}

.ui.tabular.menu {
    flex-wrap: wrap;
}