.messages-page {
    padding: 0 1.2rem;
    tfoot {
        background-color: #cce2ff;
    }
    .filters {
        display: flex;
        align-items: center;
        > div {
            padding: 0 0.5rem;
        }
    }
    .message-count {
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
        line-height: 1.2;
    }
    .caption {
        color: rgb(160, 160, 160);
        font-weight: 600;
    }
    .ml-4 {
        margin-left: 4px !important;
    }
    .table-container{
        padding-top: 20px;
        padding-bottom: 40px;
        width: 100%;
        overflow-x: auto;
        max-width: 100%; 
    }
    .error-info{
        color: red;
        font-weight: 600;
    }
    .ui.inverted.dimmer{
        background-color: hsla(0,0%,100%,.65);
    }
    .download-btn{
        margin-bottom: 16px !important;
    }
    .ui.celled.table th{
        cursor: pointer;
    }
    
table {
    table-layout: fixed; 
    width: 100%;
  }
  .ui.celled.table th,
.ui.celled.table td {
    white-space: normal;
    word-wrap: break-word;
}
  
}
