.item.very.specific.class.RequestItem {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-top: 4px;
    padding-bottom: 4px;

    &:hover {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
}
.request-list{
    max-height: 65vh; 
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px 0px;
}
.ui.items {
    margin: 1em 0;
}
@media (max-width: 576px) {
    .item.very.specific.class.RequestItem .profile-pic {
        max-width: 46px !important;
        display: inline-flex;
        align-items: center;
    }
    .item.very.specific.class.RequestItem {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .ui.items > .item > .content {
        margin: 0.3em 0 0.3em !important;
        line-height: 0.7 !important;

        // margin: 0.3em 0 0.3em;
        // line-height: 0.7;

        .meta {
            line-height: 0.7 !important;
            // line-height: 0.7;
        }

        .header:not(.ui) {
            font-size: 1em !important;
            // font-size: 1em;
        }
    }
    .staff-dashboard .Requests {
        padding: 0;
    }
}
