.NewPassword {
    text-align: center;
    height: 100%;
    padding: 40px;
    max-width: 620px;
    margin: 0 auto;
}

.NewPassword .formBox .input {
    /* display: block; */
    /* width: 100%; */
    margin: 10px 0;
}

.NewPassword .logo {
    max-width: 100%;
    margin-bottom: 20px;
}
