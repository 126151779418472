@import '../../semantic-ui/site/globals/site.variables';

.ui.dimmer .ui.modalLoader.loader:before {
    border-color: rgba(0, 0, 0, .1);
}

.ui.dimmer .ui.modalLoader.loader:after {
    border-color: #767676 transparent transparent;
}

.create-request {
    margin-left: 5px;
    border: 0;
    outline: 0;
    cursor: pointer;
    text-align: center;
    text-align: center;
    color: @primaryColor;
    border-radius: 5px;

    &:hover {
        background: #eee;
    }

    .button-icon {
        font-size: 22px;
        margin: auto;
    }
}

.flexbox {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.orderHeading {
    margin-bottom: 0px;
}

.noMarginPadding {
    margin: 0 !important;
    padding: 0 !important;
}

.centered {
    text-align: center;
}

.headerFontSize {
    font-size: 0.9rem !important;
    font-weight: 900;
    margin: 0;
    padding: 0;
}

.calendarRow {
    .alignItemsCenter {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }

    .date-picker-container {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 10px;
    }

    @media (max-width: 768px) {
        .alignItemsCenter {
            flex-direction: column;
        }

        .date-picker-container {
            width: 100%;
        }
    }
}