.UsersHome {
    padding: 0 20px;
    padding-bottom: 20px;
}
.user-icon{
    padding-top: '8px';
    padding-bottom: '8px' ;
    cursor: pointer;
}
.user-icon :hover{
    scale: 1.2;
}