.FileUploadPresentation {
    width: 100%;
    border: 1px solid lightgrey;
    padding: 40px;
    color: darkgrey;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-align: center;

    .text {
        font-size: 16px;
        margin-top: 10px;
    }

    .file-input {
        display: none;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        color: white;
        text-align: center;
        font-size: 39px;
    }
}