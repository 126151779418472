.service-icons,
.calendar-container {
    display: flex;
    margin: 20px 0px;
    justify-content: flex-end;

    .searchBtnPlus {
        margin-left: 10px;
    }
}

.react-datepicker__navigation {
    padding: 0px !important;
    height: 2em;
    border-width: 1em;
    width: 3.5em;
    margin-top: -0.25em;
}

.fc-media-screen {
    height: 800px;
}

.create-instance-form {
    .form {
        margin: 0em;
    }

    .self-centre {
        display: flex;
        justify-content: center;
        align-self: center;
        padding: 10px;
        gap: 10px;
    }

    .flex-centre {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
    }

    .audio-recorder {
        margin-right: 12px;
        flex-basis: 40%;

        .recording-audio {
            text-align: center;
            margin-right: 22px;
            margin-left: 30px;
            margin-top: 17px;
            padding-right: 5px;
        }

        .recording-state-image {
            cursor: pointer;

            .audio-arrow {
                display: inline-block;
                height: 4.5em;
                color: rgb(52, 166, 209);
                padding-right: 5px;

                @media screen and (max-width: 376px) {
                    height: 3.5em;
                }

                @media screen and (max-width: 330px) {
                    height: 3em;
                    margin-bottom: 2px;
                }
            }

            .mic {
                display: inline-block;
                line-height: 1em;
                color: rgb(52, 166, 209);
                cursor: pointer;
                height: 92px;
                margin-top: 6px;
                padding-top: 16px;
            }

            p {
                font-size: 12px;
                margin-top: auto;
                padding-top: 10px;
                margin-right: 2px;
            }

            .recording-button {
                font-size: 0;
                background-color: red;
                border: 0;
                border-radius: 50%;
                outline: none;
                cursor: pointer;
                margin: auto;
                margin-top: 52px;
                width: 40px;
                height: 40px;
                animation-name: pulse-record;
                animation-duration: 1.5s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
        }

        .audio-exists {
            text-align: center;
            padding-top: '5px';

            button {
                margin-top: 3px;
            }
        }

        .current-audio {
            text-align: center;
            padding: 8px 0;
        }
    }
}

.center-self {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-time-slot {
    background-color: #f5f5f5;
    padding: 2px;
    margin: 2px;
    cursor: pointer;
    font-size: 12px;
    text-align: center;
    border: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fc-time-grid .fc-slats .fc-slot:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.fc-time-grid .fc-time.fc-widget-content {
    background-color: transparent !important;
}

.accordion-message {
    font-size: 1.1rem;
    margin-left: 10px;
    text-align: center;
}

#external-events {
    margin-top: 17px;
}
.calendarEventSVC {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
}