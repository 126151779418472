.filter-block-new {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
}

.filter-block-new>div {
    width: 100%;
}

.filter-block-new>div>p {
    color: #979fab !important;
    margin-top: 25px;
}

.filter-block-new {
    padding-left: 36px;
}

.right-element.icon-block-table {
    padding-right: 10px;
}

.count-row {
    color: #979fab !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    margin-top: 10px;
    margin-left: 4vw;
}

.count-column {
    width: 18% !important;
    font-size: 1px !important;
    color: #5e5e5e !important;
}

.count-item {
    overflow: hidden;
    padding: 5px !important;
    cursor: pointer;
}

.small-text {
    font-size: 13px !important;
}

.table-containter {
    margin-right: 2vw;
}