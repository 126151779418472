.ui.label.act-intellectual {
  background-color: #F9CE21 !important;
}

.ui.label.act-occupational {
  background-color: #E98530 !important;
}

.ui.label.act-physical {
  background-color: #183466 !important;
}

.ui.label.act-social {
  background-color: #3D9CBF !important;
}

.ui.label.act-emotional {
  background-color: #3C1B49 !important;
}

.ui.label.act-spiritual {
  background-color: #FFBF00 !important;
}

.ui.label.act-environmental {
  background-color: #69B546 !important;
}

.ui.label.act-virtual {
  background-color: #972b28 !important;
}

.copyBtn {
  background-color: #69B546 !important;
  color:white !important; 
}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
  width: 100%;
}