.RegistrantsList {
    padding: 15px 0px;
    padding-bottom: 20px;

    .regSearch {
        display: flex;
        gap: 2px;
    }
}

.spacing {
    margin-top: 10px;
}

.lessSpacing {
    margin-top: 5px;
}