.highlight-textarea-wrapper {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.textarea,
.highlighted-text {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
  box-sizing: border-box;
}

.textarea {
  position: relative;
  background: none;
  color: transparent;
  /* hide the text because its overlapping with div text */
  caret-color: rgb(0, 255, 13);
  /* even if the color is transparent keep the caret visible */
  border: 1px solid green;
  /* Default green border */
  resize: none;
  overflow: auto;
  height: 108px;
  border-radius: 16px;
  outline: none;
  /* Remove the default browser focus outline */
  transition: border-color 0.3s ease;
  /* Smooth transition for border color */
  border-color: limegreen;
  /* Change to a brighter green when focused */
  box-shadow: 0 0 2px limegreen;
  /* Optional: add a glow effect */
}


.highlighted-text {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  border: 1px solid transparent;
  height: 150px;
  overflow: hidden;
}

.highlight {
  color: red;
  font-weight: bold;
}