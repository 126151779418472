
.fc-col-header-cell-cushion {
    color:  #2987CD !important;
}

.fc-daygrid-day-number {
    color:  #2987CD !important;
}

.fc-addItem-button {
    font-weight: bold !important;
    font-size: 1.65rem !important;
    padding: 0px 10px !important;
}

.fc-print-button {
    padding: 0.5em 1em !important;
}

.fc-export-button {
    padding: 0.5em 1em !important;
}

.fc-today-button {
    padding: 0.5em 1em !important;
}

.fc-prev-button {
    padding: 0.5em 1em !important;
}

.fc-next-button {
    padding: 0.5em 1em !important;
}

div.DailyActivities {
    padding-top: 0px !important;
}

.button-class{
    position: relative;
    top: 8px ;
    left: 5px;
}
.views-tabs{
    margin-top: 0px;
    padding-top: 0px;
}
.DailyActivities .ui.form {
    margin-bottom: 0em;
}
.ui.tiny.form {
    z-index: 1;
}