@import '../../../../semantic-ui/site/globals/site.variables';

.UnitsForm {
    padding: 0 20px;
    padding-bottom: 25px;

    .container {
        display: flex; /* or inline-flex */
        .item {
            flex: 8;
        }
        .cancel {
            flex: 1;
            padding: 10px 10px 10px 10px;
        }
    }

    .add-id {
        padding: 10px 10px 10px 10px;
        margin: 10px 10px 10px 0px;
    }
}
