form {
    margin-top: 30px;

    // .start-date-form-field {
    //     display: inline-block;
    //     width: 50%;
    // }
    // .end-date-form-field {
    //     display: inline-block;
    //     width: 50%;
    // }
}

.ql-editor {
    height: 20em !important;
}
.menu-dropdown {
    text-transform: capitalize;
}
.menu-dropdown .menu .item .text {
    text-transform: capitalize;
}


.ui.label.menu-breakfast {
    background-color: #2987CD !important;
}

.ui.label.menu-lunch {
    background-color: #69B546 !important;
}

.ui.label.menu-dinner {
    background-color: #EAC31F !important;
}

.ui.label.menu-snack {
    background-color: #E98530 !important;
}

.ui.label.menu-alternative {
    background-color: #E98530 !important;
}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
    width: 100%;
}

.my-8 {
    margin: 16px 0px !important;
}

.react-kanban-column {
    overflow-y: scroll !important;
    height: 90vh !important;
}
