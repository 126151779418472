.smart-gadgets{
    order: 2;
    border: 2px solid rgb(92, 184, 92);
    border-radius: 4px;
    // width: 49%;
    padding: 10px;
    margin-bottom: 20px;

    p:nth-child(1) {
        font-size: 12px;
        color: rgb(170, 170, 170);
    }

    @media screen and (max-width: 767px) {
        width: 100%;
        padding-bottom: 30px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .ONLINE {
        color: #1d8102;
    }

    .OFFLINE {
        color: #d13212;
    }
}