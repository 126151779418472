.alis-integration {
    border: 2px solid rgb(3, 202, 252);
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
    width: 50%;

    @media screen and (max-width: 767px) {
        width: 100%;
        padding-bottom: 30px;
        margin-top: 20px;
        margin-bottom: 10px;
    }
}