@import '../../semantic-ui/site/globals/site.variables';

.navigation {
    .page-header {
        padding: 10px 15px 0px 15px;

        .items {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .page-name {
                flex-grow: 1;
                font-size: 18px;
                color: @primaryColor;
                text-align: center;
                margin: 0;
            }
            .right {
                .nav-item {
                    margin-right: 10px;
                }
            }
            // .username {
            //     // font-size: 18px;
            // }
        }
    }

    .ui.inverted.menu {
        background-color: @primaryColor;
    }

    #divider {
        border-color: #DEDEDF;
        margin-bottom: 10px;   
    }
}
.community-popup {
    left: 180px !important;
}

.ask-jillian {
    margin-right: 11px;
    height: 1em;
    margin-top: 2px;
}

@media (max-width: 576px) {
    .navigation {
        .page-header {
            padding: 16px;
        }
    }
}
