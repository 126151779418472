.cls-1 {
    fill: #872425;
}

.cls-2 {
    fill: #262424;
}

.cls-3 {
    fill: #3a1c49;
}