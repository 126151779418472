.App {
    width: 100%;
    height: 100%;
}

.my-4 {
    margin: 15px 0;
}

.my-2 {
    margin: 5px 0;
}

.mx-2 {
    margin: 0 5px;
}
