.searchBtnPlus{
    background-color: #ffff !important ;
   
}
.container-date{
    padding-top: 15px;
    display: flex;
    justify-content: end;
}
.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100% ;
}