.Notifications {
    padding: 10px 20px;
    // margin-bottom: 200px;
    margin-bottom: 50px;

    .NotificationItem.Comment {
        margin-bottom: 30px;
    }
}

.calendar-view {
    max-width: 1040px;
    margin: 0px 20px;
    .fc-event-title {
        white-space: normal;
        line-height: 1.24;
        overflow-wrap: break-word;
    }
    .fc-header-toolbar .fc-today-button {
        text-transform: capitalize;
    }
}

.px-4 {
    padding: 0px 8px !important;
}

.p-4 {
    padding: 8px !important;
}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
    width: 100%;
}
.ui.form.form-custom-margin .fields {
    margin: 5px 0px 5px 0px;
}

.react-datepicker-popper {  z-index: 100 !important }

.fc-col-header-cell-cushion {
    color:  #2987CD !important;
}

.fc-daygrid-day-number {
    color:  #2987CD !important;
}