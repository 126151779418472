.Speak2FamilyMessageList {
    padding: 0 20px;
    padding-bottom: 20px;
}

.disabled-message {
    opacity: 0.5;
}

.sentAtdate {
}
.messageItem {
    padding: 10px;
}

.mt-4 {
    margin-top: 8px !important;
}