
@import '../../semantic-ui/site/globals/site.variables';

.DashboardHome {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
}
.report-panel-row-new-1 > div > .report-panel-row {
    display: none;
}
.report-panel-row-new-1 > div > .filter-block {
    margin: 0px !important;
}