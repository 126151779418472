.restaurant-filter {

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 20px;

    .group-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: "pointer" ;
        gap : 10px;
    }
 
    .menu-item {
        margin-bottom: 7px;
        border: 2px solid transparent;
        display: flex;
        justify-content: space-evenly;
        background-color: white;
    }

    .menu-item.active.item {
        background-color: white;
        border: 2px solid #34a5d0;
        border-radius: 5px;
    }

    .ui.menu .menu {
        display: flex;
        justify-content: space-between;
    }

    .ui.menu {
        border: none;
        box-shadow: none
    }

    .carousel-arrows-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .angle {
        cursor: pointer;
    }

    .single-line-paragraph {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0; /* Remove margin for consistent behavior */
      }
      
      .vertical-divider {
        width: 1px;
        background-color: #d4d4d5; /* Adjust the color as needed */
        margin: 0 1rem; /* Adjust the margin as needed */
      }

}