@import '../../../../semantic-ui/site/globals/site.variables';

.SingleRequestAdmin {
    padding: 0 20px;
    text-align: center;

    img {
        display: block;
        margin: 0px auto;
    }

    .request-view {
        max-width: 500px;
        margin: 0 auto;
        border: 1px solid @primaryColor;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 50px;

        .header {
            display: flex;
            justify-content: space-between;
        }
    }

    .req-info-text {
        font-size: 18px;
        margin: 10px 0;
        color: #a0a0a0;
    }

    .primary {
        color: @primaryColor;
    }

    #acceptBtn {
        margin-top: 20px;
    }

    .faces {
        margin-top: 30px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;

        .face {
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .error {
        margin: 10px;
        color: @errorColor;
    }
}
