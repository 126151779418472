.container {
    position: relative;
    text-shadow: rgb(0, 0, 0) 1px 0 1px;
    color: white;
    filter: brightness(1.5);
    font-weight: bold;
}
  
.topleft {
    position: absolute;
    left: 16px;
    filter: brightness(1.5);
    margin: 0;
}

.bottomleft {
    position: absolute;
    bottom: 40px;
    left: 16px;
    filter: brightness(1.5);
    margin: 0;
}

.bottomleftsub {
    position: absolute;
    bottom: 8px;
    filter: brightness(1.5);
    left: 16px;
    margin: 0;
}
  
.image { 
    width: 100%;
    filter: brightness(0.6);
    height: auto;
}